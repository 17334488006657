<script>
import {
  required,
  // email,
  //   minLength,
  // sameAs,
  //   maxLength,
  // minValue,
  // maxValue,
  // numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    /*DatePicker,
    Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: "เพิ่มงานนอก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  computed: {
    /**
     * Total no. of records
     */
    //
  },


  created() {
    this.getLocalData();
  },

  data() {
    return {
      submitformOS: false,

      title: "เพิ่มงานนอก",
      items: [
        {
          text: "ข้อมูลหลัก",
        },
        {
          text: "ศูนย์บริการ",
        },
        {
          text: "งานนอก",
          href:"/outsourcing-service"
        },
        {
          text: "เพิ่มงานนอก",
        },
      ],

      loading: undefined,
      optionsSub: [],
      localDataBranch: [],
      localData: [],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      formOS: {
        branchId: "",
        osCode: "",
        nameTh: "",
        nameEn: "",
        price: "",
        workDuration: "",
        supId: "",
      },
    };
  },
  validations: {
    formOS: {
      branchId: {
        required,
      },
      osCode: {},
      nameTh: {
        required,
      },
      nameEn: {},
      price: {},
      workDuration: {},
      supId: {},
    },
  },

  methods: {
    postBranchOS: function() {
      // this.overlayFlag = true;
      const osPost = new FormData();

      osPost.append("branchId", this.formOS.branchId.branchId);
      osPost.append("osCode", this.formOS.osCode);
      osPost.append("nameTh", this.formOS.nameTh);
      osPost.append("nameEn", this.formOS.nameEn);
      osPost.append("price", this.formOS.price);
      osPost.append("workDuration", this.formOS.workDuration);
      osPost.append("supId", this.formOS.supId.supId == undefined ? '' : this.formOS.supId.supId );

      useNetw
        .post("api/outsourcing-service/store", osPost)
        .then((response) => {
          this.$router.push({ name: "outsourcing-service" });

          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
    },

    formOSSubmit() {
      this.$v.$touch();

      if (this.$v.formOS.$invalid) {
        // console.log("false");
        this.submitformOS = true;
      } else {
        this.postBranchOS();
      }
    },

    getSup: function() {
      // this.overlayFlag = true;
      useNetw
        .get("api/master/suppliers", {
          params: {
            branchId: this.formOS.branchId.branchId,
          },
        })
        .then((response) => {
          this.optionsSub = response.data.data;
          // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-skeleton-wrapper :loading="loading"
          ><!--// !skeleton start-->
          <template #loading>
            <br />
            <b-skeleton animation="wave" width="10%"></b-skeleton>
            <b-skeleton animation="wave" width="15%"></b-skeleton>

            <br />
            <div class="row">
              <div class="col-md-2">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />
            <div class="row">
              <br />
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />

            <div class="card">
              <b-skeleton-table
                :rows="5"
                :columns="2"
                :table-props="{ bordered: false, striped: true }"
              ></b-skeleton-table>
            </div>
          </template>
          <!--// !skeleton ends-->

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 my-auto">
                  <!-- <h4 class="card-title">เพิ่มสาขาย่อย</h4> -->
                </div>
                <div class="col-md-6 text-end">
                  <router-link :to="{ name: 'outsourcing-service' }">
                    <button class="btn btn-secondary">กลับสู่หน้าหลัก</button>
                  </router-link>
                </div>
              </div>
              <hr />
              <form class="needs-validation" @submit.prevent="formOSSubmit()">
                <div class="row">
                  <div class="col-md-6">
                    <code>* </code><label for="branchId">สาขา :</label>
                    <multiselect
                      v-model="formOS.branchId"
                      :options="localData"
                      label="nameTh"
                      @input="getSup"
                      :class="{
                        'is-invalid': submitformOS && $v.formOS.branchId.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitformOS && $v.formOS.branchId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formOS.branchId.required">{{
                        error
                      }}</span>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <code>* </code
                    ><label for="branchId">บริษัทที่รับผิดชอบ :</label>
                    <multiselect
                      v-model="formOS.supId"
                      :options="optionsSub"
                      label="nameTh"
                      :class="{
                        'is-invalid': submitformOS && $v.formOS.supId.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitformOS && $v.formOS.supId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formOS.supId.required">{{ error }}</span>
                    </div>
                  </div>
                </div>
                <!--//! End Rows -->

                <div class="row mt-3">
                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="dealerBranchId">รหัสงานนอก :</label>
                      <input
                        v-model="formOS.osCode"
                        type="text"
                        class="form-control"
                        placeholder="รหัสงานนอก"
                        :class="{
                          'is-invalid': submitformOS && $v.formOS.osCode.$error,
                        }"
                      />
                      <div
                        v-if="submitformOS && $v.formOS.osCode.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formOS.osCode.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="branchNameTH">ชื่องานนอก (ไทย)  :</label>
                      <input
                        v-model="formOS.nameTh"
                        type="text"
                        class="form-control"
                        placeholder="ชื่องานนอก (ไทย)"
                        :class="{
                          'is-invalid': submitformOS && $v.formOS.nameTh.$error,
                        }"
                      />
                      <div
                        v-if="submitformOS && $v.formOS.nameTh.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formOS.nameTh.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchNameEN"
                        >ชื่องานนอก (อังกฤษ)</label
                      >
                      <input
                        v-model="formOS.nameEn"
                        type="text"
                        class="form-control"
                        placeholder="ชื่องานนอก (อังกฤษ)"
                        :class="{
                          'is-invalid': submitformOS && $v.formOS.nameEn.$error,
                        }"
                      />
                      <div
                        v-if="submitformOS && $v.formOS.nameEn.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formOS.nameEn.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //!End row -->

                <div class="row">
                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchShortNameEN"
                        >ราคา (บาท)
                       :</label>
                      <input
                        v-model="formOS.price"
                        type="number"
                        class="form-control"
                        placeholder="0"
                        :class="{
                          'is-invalid': submitformOS && $v.formOS.price.$error,
                        }"
                      />
                      <div
                        v-if="submitformOS && $v.formOS.price.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formOS.price.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validationformdealerbranchShortNameEN"
                        >จำนวนชั่วโมงทำงาน
                       :</label>
                      <input
                        v-model="formOS.workDuration"
                        type="number"
                        class="form-control"
                        placeholder="0"
                        :class="{
                          'is-invalid':
                            submitformOS && $v.formOS.workDuration.$error,
                        }"
                      />
                      <div
                        v-if="submitformOS && $v.formOS.workDuration.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formOS.workDuration.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-end">
                  <button type="submit" class="btn btn-success">บันทึก</button>
                </div>
                <hr />

                
              </form>
              
            
            </div>
          </div>
          <!-- //!end card -->
        </b-skeleton-wrapper>
      </div>
    </div>
  </Layout>
</template>
